import React from 'react'

export default function SectionContainer({
  size = '5xl',
  children,
  rounded,
  border = 'border-full'
}: Props) {
  return (
    <>
      <div className="section-container">
        <div
          className={`max-w-${size} mx-auto w-full flex-1 ${border ?? ''} ${
            rounded ?? ''
          }`}
        >
          {children}
        </div>
      </div>
    </>
  )
}

interface Props {
  children: React.ReactNode
  size?: '3xl' | '4xl' | '5xl' | '6xl' | '7xl'
  rounded?: 'full' | 'bottom-right' | 'bottom-left' | 'top-right' | 'top-left'
  border?:
    | 'border-full'
    | 'border-top'
    | 'border-right'
    | 'border-bottom'
    | 'border-right'
}

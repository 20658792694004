import React from 'react'
import SectionContainer from '../components/Homepage/SectionContainer'

// Styles
// Mobile Containers
const mobileSectionContainer =
  'relative z-10 mx-6 my-12 rounded-xxlg md:m-auto bg-gp-white shadow-uniform md:bg-transparent md:shadow-none'

const mobileSectionContainerHighlight = `${mobileSectionContainer} border-4 md:border-0  border-gp-violet`

const Homepage = () => {
  return (
    <>
      <SectionContainer rounded="bottom-right" border="border-right border-bottom" />
    </>
  )
}

export default Homepage
